<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    version="1.0"
    viewBox="0 0 64 64"
  >
    <g>
      <path
        d="M43.416 35.812a1 1 0 0 1 1.168-1.624L64 48.168V15.475L32.597 38.803a.998.998 0 0 1-1.194 0L0 15.475v32.693l19.416-13.979a1 1 0 0 1 1.168 1.624L0 50.632V52c0 2.211 1.789 4 4 4h56c2.211 0 4-1.789 4-4v-1.368l-20.584-14.82z"
      />
      <path
        d="m32 36.754 32-23.771V12c0-2.211-1.789-4-4-4H4c-2.211 0-4 1.789-4 4v.982l32 23.772z"
      />
    </g>
  </svg>
</template>