<template>
  <section class="section">
    <w-c-title
      class="mb-8 sm:mb-16"
      title="Hospedaje"
      v-scrollanimation="'enter-from-bottom'"
    />

    <div
      v-for="(accommodation, index) in accommodations"
      :key="accommodation.id"
      class="flex flex-col w-full sm:w-96 mx-auto shadow-primary z-40 rounded-lg bg-background-600 text-background-700 p-6 text-center"
      :class="[{ 'mb-8 md:mb-16': index < accommodations.length - 1 }]"
      v-scrollanimation="
        index % 2 === 0 ? 'enter-from-left' : 'enter-from-right'
      "
    >
      <h3 class="special">
        {{ accommodation.name }}
      </h3>

      <p v-if="accommodation.message" class="mt-4">
        {{ accommodation.message }}
      </p>
      <a
        class="mx-auto text-sm mt-4"
        :class="[
          {
            'transform block smooth focus:outline-none cursor-pointer hover:scale-105 underline':
              accommodation.addressLink,
          },
        ]"
        :href="accommodation.addressLink"
        target="_blank"
        :disabled="!accommodation.addressLink"
      >
        {{ accommodation.address }}
      </a>

      <a
        v-if="accommodation.link"
        class="secondary-btn mx-auto mt-4"
        :href="accommodation.link"
        target="_blank"
        >Sitio del Hotel</a
      >
    </div>
  </section>
</template>

<script>
import ArrowIcon from "../../../icons/ArrowIcon";
export default {
  name: "Accommodations",

  components: {
    ArrowIcon,
  },

  props: {
    accommodations: Array,
  },
};
</script>

<style scoped>
.special {
  @apply font-special text-4xl mx-auto;
}
.secondary-btn {
  @apply flex flex-shrink-0 w-fit justify-center items-center text-sm h-8 px-4 rounded-lg button-interactive border border-background-700 text-background-700 hover:bg-background-700 hover:text-white;
}
</style>