<template>
  <div class="flex flex-col absolute inset-0">
    <router-view />
    <transition name="snack">
      <snack v-if="snack" :snack="snack" />
    </transition>
  </div>
</template>

<script>
import Snack from "./components/Snack";
import { mapState } from "vuex";
import { useHead } from '@unhead/vue'
export default {
  name: "App",

  setup() {
    useHead({
      title: 'Daisi',
      meta: [
        { 
          name: 'description', 
          content: 'Tu invitación de boda inteligente.' 
        },
        { 
          property: 'og:title', 
          content: 'Daisi' 
        },
        { 
          property: 'og:description', 
          content: 'Tu invitación de boda inteligente.' 
        },
        { 
          property: 'og:url', 
          content: 'https://www.daisi.mx' 
        },
        { 
          property: 'og:image', 
          content: 'https://daisi.mx/daisi_logo.png' 
        },
        { 
          property: 'og:type', 
          content: 'website' 
        },
        { 
          name: 'twitter:card', 
          content: 'summary_large_image' 
        },
      ],
    })
  },

  components: {
    Snack
  },

  computed: {
    ...mapState({
      snack: "snack",
    }),
  },
};
</script>

<style >
.snack-enter-active,
.snack-leave-active {
  transition: all 0.3s ease;
}
.snack-enter-from,
.snack-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.before-enter {
  opacity: 0;
  transition: all 2s ease-out;
}
.enter {
  opacity: 1;
}
.enter-from-top {
  animation: enterFromTop 2s ease forwards;
}
.enter-from-left {
  animation: enterFromLeft 2s ease forwards;
}
.enter-from-right {
  animation: enterFromRight 2s ease forwards;
}
.enter-from-bottom {
  animation: enterFromBottom 2s ease forwards;
}
@keyframes enterFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes enterFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes enterFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes enterFromBottom {
  from {
    transform: translateY(100%);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>