<template>
  <section class="section">
    <w-c-title
      class="mb-4 sm:mb-12"
      title="Mesa de regalos"
      v-scrollanimation="'enter-from-bottom'"
    />

    <div class="flex flex-wrap justify-center">
      <div
        v-for="registry in giftRegistries"
        :key="registry.id"
        class="flex flex-col bg-background-500 shadow-primary z-40 p-6 justify-center rounded-lg mx-4 mt-4"
        v-scrollanimation="'enter-from-bottom'"
      >
        <h3
          v-if="!registry.link"
          class="subtitle text-lg mx-auto text-background-700"
        >
          {{ registry.name }}
        </h3>

        <a
          v-else
          class="subtitle text-lg mx-auto text-background-700 button-interactive underline"
          :href="registry.link"
          target="_blank"
        >
          {{ registry.name }}
        </a>
      </div>
    </div>

    <envelope-icon
      v-if="acceptsEnvelope"
      class="w-10 md:w-12 mt-4 mx-auto text-background-700 fill-current"
      v-scrollanimation="'enter-from-bottom'"
    />
  </section>
</template>

<script>
import EnvelopeIcon from "../../../icons/EnvelopeIcon";
export default {
  name: "Gift Registry",

  components: {
    EnvelopeIcon,
  },

  props: {
    giftRegistries: Array,
    acceptsEnvelope: Boolean,
  },
};
</script>