<template>
  <section class="section text-left">
    <w-c-title
      class="mb-8 sm:mb-16"
      title="Confirma tu asistencia"
      v-scrollanimation="'enter-from-bottom'"
    />
    <div
      class="mx-auto bg-white p-8 shadow-primary rounded-lg w-full sm:w-3/4 md:w-2/3 lg:w-2/5 flex flex-col"
      v-scrollanimation
    >
      <div v-if="loadingGuest" class="flex w-full h-full items-center">
        <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
      </div>

      <div v-else-if="guest">
        <div class="w-full flex flex-col">
          <p class="text-3xl font-special mt-2 mx-auto text-primary-500 text-center">
            {{ guest.name }} {{ guest.lastName }}
          </p>

          <p v-if="guest.specialMessage" class="text-2xl font-special mt-2 text-center">
            {{ guest.specialMessage }}
          </p>

          <p class="mx-auto text-content-400 mt-2 text-center">
            Invitación para
            {{
              guest.maxGuestsAllowed === 1
                ? "1 persona"
                : `${guest.maxGuestsAllowed} personas`
            }}
          </p>
        </div>

        <div>
          <div class="flex flex-col mt-4">
            <custom-label
              for="confirmation"
              value="¿Asistirás a la boda?"
              :required="true"
            />
            <select
              class="border py-2 px-3 rounded-lg outline-none smooth focus:border-primary-500 smooth w-full"
              id="confirmation"
              v-model="form.confirmation"
              @change="selectedConfirmation"
              :disabled="guest.confirmation !== null && !updateConfirmation"
            >
              <option :value="null" selected>Seleccionar</option>
              <option :value="true">Sí</option>
              <option :value="false">No</option>
            </select>
          </div>

          <div
            v-if="form.confirmation && guest.maxGuestsAllowed > 1"
            class="flex flex-col mt-4"
          >
            <custom-label
              for="confirmedGuests"
              value="¿Cuántos asistirán?"
              :required="true"
            ></custom-label>
            <select
              class="border py-2 px-3 rounded-lg outline-none smooth focus:border-primary-500 smooth w-full"
              id="confirmation"
              v-model="form.confirmedGuests"
              :disabled="guest.confirmation !== null && !updateConfirmation"
            >
              <option :value="null" selected>Seleccionar</option>
              <option
                v-for="(number, index) in guestsAllowed"
                :key="index"
                :value="number"
              >
                {{ number }}
              </option>
            </select>
          </div>

          <div v-if="foodRestrictionsEnabled && form.confirmation" class="flex flex-col mt-4">
            <custom-label for="message" value="¿Tienes alguna restricción de alimentos?"></custom-label>
            <textarea
              class="h-36 w-full border py-2 px-3 rounded-lg outline-none focus:border-primary-500 smooth"
              id="message"
              v-model="form.foodRestrictions"
              :disabled="guest.confirmation !== null && !updateConfirmation"
            ></textarea>
          </div>

          <div v-if="messageEnabled && form.confirmation !== null" class="flex flex-col mt-4">
            <custom-label for="message" value="Escribe un mensaje para los novios"></custom-label>
            <textarea
              class="h-36 w-full border py-2 px-3 rounded-lg outline-none focus:border-primary-500 smooth"
              id="message"
              v-model="form.message"
              :disabled="guest.confirmation !== null && !updateConfirmation"
            ></textarea>
          </div>

          <button
            v-if="
              formIsCompleted &&
              (guest.confirmation === null || updateConfirmation)
            "
            :disabled="loading"
            type="submit"
            @click.prevent="submitConfirmation"
            class="primary-btn mt-6 w-full"
          >
            <loader-icon v-if="this.loading" class="h-5 w-5"></loader-icon>
            <slot v-else>Enviar</slot>
          </button>

          <div
            v-if="guest.confirmation !== null && confirmationEnabled"
            class="flex mt-4"
          >
            <button
              v-if="!updateConfirmation"
              @click.prevent="updateConfirmation = true"
              class="button-interactive mx-auto p-2 text-primary-500"
            >
              Modificar Confirmación
            </button>

            <button
              v-else
              @click.prevent="
                updateConfirmation = false;
                form.message = null;
              "
              class="button-interactive mx-auto p-2"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="errorLoadingGuest">
          <p class="text-error-500 text-center">
            Hubo un problema al cargar tu información. Por favor asegúrate que
            el código utilizado es válido.
          </p>
        </div>

        <div class="flex flex-col mt-4">
          <custom-label for="code" value="Ingresa tu código"></custom-label>
          <custom-input id="code" v-model="code" type="text" />
          <button
            :disabled="loadingGuest"
            type="submit"
            @click.prevent="loadGuest(code)"
            class="primary-btn mt-6 w-full"
          >
            <loader-icon v-if="this.loadingGuest" class="h-5 w-5"></loader-icon>
            <slot v-else>Enviar</slot>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoaderIcon from "../../../icons/LoaderIcon";
import CustomLabel from "../../../components/CustomLabel";
import CustomInput from "../../../components/CustomInput";
import api from "../../../api";
export default {
  name: "Confirmation",

  components: {
    LoaderIcon,
    CustomLabel,
    CustomInput,
  },

  data() {
    return {
      loadingGuest: false,
      guest: null,
      form: {
        code: null,
        confirmation: null,
        confirmedGuests: null,
        foodRestrictions: null,
        message: null,
      },
      errorLoadingGuest: false,
      loading: false,
      code: null,
      updateConfirmation: false,
    };
  },

  props: {
    confirmationEnabled: Boolean,
    foodRestrictionsEnabled: Boolean,
    messageEnabled: Boolean,
  },

  computed: {
    guestsAllowed() {
      if (!this.guest) {
        return null;
      }

      const arr = [];

      for (let i = 1; i <= this.guest.maxGuestsAllowed; i++) {
        arr.push(i);
      }

      return arr;
    },

    formIsCompleted() {
      return (
        this.form.code != null &&
        this.form.confirmation != null &&
        (this.form.confirmation
          ? this.form.confirmedGuests != null
          : this.form.confirmedGuests === null)
      );
    },
  },

  created() {
    if (this.$route.params.code) {
      this.loadGuest(this.$route.params.code);
    }
  },

  methods: {
    selectedConfirmation() {
      if (this.form.confirmation === false) {
        this.form.confirmedGuests = null;
        this.form.foodRestrictions = null;
      } else {
        if (this.guest.maxGuestsAllowed === 1) {
          this.form.confirmedGuests = 1;
        }
      }
    },

    loadForm(guest) {
      const { code, confirmation, confirmedGuests, foodRestrictions, message } = guest;
      this.form = {
        ...this.form,
        code,
        confirmation,
        confirmedGuests,
        foodRestrictions,
        message,
      };
    },

    async loadGuest(code) {
      this.loadingGuest = true;

      try {
        this.guest = await api.guests.getByCode({
          code: code,
        });
        this.loadForm(this.guest);
        this.$emit("setGuest", this.guest);
      } catch (error) {
        this.errorLoadingGuest = true;
      }

      this.loadingGuest = false;
    },

    async submitConfirmation() {
      this.loading = true;

      try {
        this.guest = await api.guests.confirm(this.form);
        this.loadForm(this.guest);

        this.updateConfirmation = false;

        this.$store.dispatch(
          "successSnack",
          "Confirmación enviada exitosamente."
        );
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>