export default {
  path: '/weddings',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async getBySlug(form) {
    try {
      const response = await this.apiClient.post(`${this.path}/get-by-slug`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
}