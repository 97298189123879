<template>
  <label class="text-sm font-semibold">
    <span v-if="value">{{ value }}</span>
    <span v-if="required" class="text-error-500">*</span>
  </label>
</template>

<script>
export default {
  props: ["value", "required"],
};
</script>

