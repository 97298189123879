<template>
  <section class="section">
    <div
      class="flex flex-col p-4 bg-background-600 shadow-primary z-40 rounded-full w-40 h-40 justify-center"
      v-scrollanimation
    >
      <p
        class="subtitle text-lg mx-auto text-background-700"
      >
        {{ dressCode }}
      </p>

      <div v-if="showDressCodeIcons" class="flex mx-auto mt-2">
        <dress-icon
          class="w-12 h-12 md:w-12 md:h-12 text-background-700 fill-current"
        />
        <suite-icon
          class="w-12 h-12 md:w-12 md:h-12 text-background-700 fill-current"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SuiteIcon from "../../../icons/SuiteIcon";
import DressIcon from "../../../icons/DressIcon";
export default {
  name: "Dress Code",

  components: {
    SuiteIcon,
    DressIcon,
  },

  props: {
    dressCode: String,
    showDressCodeIcons: Boolean,
  },
};
</script>