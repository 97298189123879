<template>
  <section class="section">
    <transition name="modal">
      <gallery-modal
        v-if="showGallery"
        :images="availableEvents[selectedIndex].images"
        :initialIndex="0"
        @close="closeModal"
      ></gallery-modal>
    </transition>

    <w-c-title
      class="mb-8 sm:mb-16"
      title="Eventos"
      v-scrollanimation="'enter-from-bottom'"
    />

    <div
      v-for="(event, index) in availableEvents"
      :key="event.id"
      class="flex flex-col w-full sm:w-4/5 md:w-3/4 lg:w-1/2 mx-auto shadow-primary z-40 bg-background-500 p-8"
      :class="[{ 'mb-8 md:mb-16': index < availableEvents.length - 1 }]"
      v-scrollanimation="'enter-from-left'"
    >
      <h3 class="subtitle">{{ event.name }}</h3>
      <h3 class="special text-primary-500">
        {{ event.place }}
      </h3>
      <a
        class="mx-auto text-sm md:text-base text-content-400"
        :class="[{ 'button-interactive underline': event.addressLink }]"
        :href="event.addressLink"
        target="_blank"
        :disabled="!event.addressLink"
      >
        {{ event.address }}
      </a>
      <p class="subtitle mt-2 md:mt-4">{{ getTime(event.datetime) }}</p>
      <p v-if="event.onlyAdults" class="mx-auto text-content-300">
        Sólo adultos
      </p>

      <a
        v-if="event.showAddToCalendar"
        class="secondary-btn mx-auto mt-4"
        :href="googleCalendarLink(event)"
        target="_blank"
        >Agregar a Calendario</a
      >

      <!-- <div
        class="cursor-pointer w-12 h-12 mx-auto mt-4 hover:opacity-80 smooth"
        @click="openModal(index)"
      >
        <img v-lazy="event.images[0].src" class="w-full h-full object-cover" />
      </div> -->

      <button
        v-if="event.images.length > 0 && hasEventImages"
        class="secondary-btn mx-auto mt-4"
        @click="openModal(index)"
      >
        Galería
      </button>
    </div>
  </section>
</template>

<script>
import GalleryModal from "../../../components/GalleryModal";
export default {
  name: "Events",

  components: {
    GalleryModal,
  },

  data() {
    return {
      showGallery: false,
      selectedIndex: null,
    };
  },

  props: {
    events: Array,
    guest: Object,
    weddingName: String,
    hasEventImages: Boolean,
  },

  computed: {
    availableEvents() {
      if (!this.events) {
        return [];
      }

      return this.events.filter((event) => {
        if (!event.isExclusive) {
          return true;
        } else {
          if (!this.guest || !Array.isArray(this.guest.events)) {
            return false;
          } else {
            return this.guest.events.some(
              (guestEvent) => guestEvent.id === event.id
            );
          }
        }
      });
    },
  },

  methods: {
    getTime(datetime) {
      if (!datetime) {
        return null;
      }
      const date = new Date(datetime);
      const options = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/Mexico_City",
      };
      return date.toLocaleTimeString("en-US", options);
    },

    googleCalendarLink(event) {
      let datetime = new Date(event.datetime);

      // Google Calendar requires start and end times for the "dates" parameter
      let startTime = datetime.toISOString().replace(/-|:|\.\d+/g, "");
      let endTime = new Date(datetime.getTime() + 1 * 60 * 60 * 1000); // let's assume 2 hours duration
      endTime = endTime.toISOString().replace(/-|:|\.\d+/g, "");

      let url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
        this.weddingName + " - " + event.name
      )}&dates=${startTime}/${endTime}&location=${encodeURIComponent(
        event.addressLink
      )}&sf=true&output=xml`;

      return url;
    },

    openModal(index) {
      document.body.style.overflow = "hidden";
      this.selectedIndex = index;
      this.showGallery = true;
    },

    closeModal() {
      document.body.style.overflow = "";
      this.showGallery = false;
    },
  },
};
</script>

<style scoped>
.special {
  @apply font-special text-3xl sm:text-4xl md:text-5xl mx-auto;
}
.secondary-btn {
  @apply flex flex-shrink-0 w-fit justify-center items-center text-sm h-8 px-4 rounded-lg button-interactive border border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white;
}
</style>