<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 95.844 95.844"
  >
    <path
      d="m47.918 39.699 4.824-27.039-3.87-5.34 1.899-3.809h-5.705l1.903 3.809-3.876 5.34z"
    />
    <path
      d="M75.541 86.669h7.621L81.26 54.281 77.443 11.43 59.35 3.812 57.445.003l-1.903 7.618-7.623 43.807-7.618-43.81L38.393 0 36.49 3.809l-18.094 7.617-3.811 42.855-1.902 32.388h7.619l6.952-42.032 2.568 9.639-7.621 32.045 21.905 9.523 3.809-18.743 3.807 18.743 21.909-9.523-7.625-32.045 2.57-9.639 6.965 42.032zm-7.542-59.41-7.741 1.073 5.119-6.312 2.622 2.026v3.213z"
    />
  </svg>
</template>