<template>
  <section class="section">
    <p class="message" v-scrollanimation="'enter-from-top'">
      {{ invitation.firstMessage }}
    </p>

    <div class="flex flex-wrap items-center w-full justify-evenly">
      <div
        v-if="invitation.bridesParents"
        class="flex flex-col mt-6 items-center"
        v-scrollanimation="'enter-from-left'"
      >
        <div
          v-for="parent in invitation.bridesParents"
          :key="parent.id"
          class="flex items-center"
        >
          <p class="parent">
            {{ parent.name }}
          </p>
          <cross-icon v-if="parent.showIfPastAway" class="ml-2 cross" />
        </div>
      </div>

      <div
        v-if="invitation.groomsParents"
        class="flex flex-col mt-6 items-center"
        v-scrollanimation="'enter-from-right'"
      >
        <div
          v-for="parent in invitation.groomsParents"
          :key="parent.id"
          class="flex items-center"
        >
          <p class="parent">
            {{ parent.name }}
          </p>
          <cross-icon v-if="parent.showIfPastAway" class="ml-2 cross" />
        </div>
      </div>
    </div>

    <p class="message mt-6" v-scrollanimation="'enter-from-bottom'">
      {{ invitation.secondMessage }}
    </p>
  </section>
</template>

<script>
import CrossIcon from "../../../icons/CrossIcon";
export default {
  name: "Invitation",

  components: {
    CrossIcon,
  },

  props: {
    invitation: Object,
  },
};
</script>

<style scoped>
.message {
  @apply font-title mx-auto text-lg md:text-xl;
}
.parent {
  @apply font-special text-2xl md:text-3xl;
}
.cross {
  @apply w-3 h-3 sm:w-4 sm:h-4 fill-current text-primary-500;
}
</style>