<template>
  <div v-if="wedding">
    <audio
      v-if="wedding.song && wedding.hasSong"
      ref="audioPlayer"
      loop
      :autoplay="wedding.song.playAutomatically"
    >
      <source :src="wedding.song.src" type="audio/mpeg" />
    </audio>

    <button
      v-if="wedding.song && wedding.hasSong"
      class="fixed top-8 left-8 bg-white text-content-500 rounded-full p-3 fill-current button-interactive shadow-primary z-50"
      :class="[{ 'animate-bounce': !audioPlaying }]"
      @click="toggleAudio"
    >
      <play-icon v-if="!audioPlaying" class="w-4 h-4" />
      <pause-icon v-else class="w-4 h-4" />
    </button>

    <etiqueta v-if="wedding.design.slug === 'etiqueta'" :wedding="wedding" />
  </div>

  <div v-else></div>
</template>

<script>
import Etiqueta from "../designs/etiqueta/Etiqueta";
import PlayIcon from "../icons/PlayIcon";
import PauseIcon from "../icons/PauseIcon";
import api from "../api";
import { useHead } from "@unhead/vue";
export default {
  name: "Home",

  components: {
    Etiqueta,
    PlayIcon,
    PauseIcon,
  },

  data() {
    return {
      wedding: null,
      audioPlaying: false,
    };
  },

  created() {
    this.loadWedding();
  },

  methods: {
    async loadWedding() {
      try {
        this.wedding = await api.weddings.getBySlug({
          slug: this.$route.params.slug,
        });

        this.audioPlaying = this.wedding.song
          ? this.wedding.song.playAutomatically
          : false;

        useHead({
          title: this.wedding.name,
          meta: [
            {
              property: "og:title",
              content: this.wedding.name,
            },
            {
              property: "og:image",
              content: `${process.env.VUE_APP_S3_URL}${process.env.NODE_ENV != 'production' ? `/dev` : ''}/weddings/${this.wedding.id}/ogImage/image.jpg`,
            },
            {
              property: "og:description",
              content: `Con la bendición de Dios y el amor de nuestros Padres tenemos el honor de invitarte a celebrar nuestra unión en matrimonio.`,
            },
          ],
        });
      } catch (error) {
        this.$router.push({ name: "not_found" });
      }
    },

    toggleAudio() {
      const audioPlayer = this.$refs.audioPlayer;

      if (this.audioPlaying) {
        audioPlayer.pause();
      } else {
        audioPlayer.play();
      }

      this.audioPlaying = !this.audioPlaying;
    },
  },
};
</script>