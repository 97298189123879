<template>
  <input
    :id="id"
    :type="type"
    :placeholder="placeholder"
    class="border py-2 px-3 rounded-lg outline-none smooth focus:border-primary-500"
    :value="modelValue"
    @input="updateValue"
    ref="input"
  />
</template>

<script>
export default {
  props: ["id", "type", "modelValue", "placeholder"],

  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>
