import axios from "axios";
import weddings from "./weddingRequests";
import guests from "./guestRequests";

const apiUrl = process.env.VUE_APP_API_URL;
const apiVersion = process.env.VUE_APP_API_VERSION;

const apiClient = axios.create({
  baseURL: `${apiUrl}/${apiVersion}`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

weddings.setClient(apiClient);
guests.setClient(apiClient);

export default {
  weddings,
  guests,
};
