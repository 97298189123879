<template>
  <header class="w-full h-full inset-0 bg-cover bg-center">
    <div
      v-if="isCoverImageLoaded"
      class="absolute inset-0 object-cover overflow-hidden"
      v-scrollanimation
    >
      <img
        ref="coverImage"
        class="w-full h-full object-cover"
        alt=""
        :src="getCoverImage()"
      />
    </div>
    <div
      class="absolute inset-0 bg-black bg-opacity-30 text-white flex flex-col justify-center"
    >
      <h1 class="special mt-6" v-scrollanimation>{{ name }}</h1>

      <div
        v-if="hasCountdown && !weddingCompleted"
        class="flex mt-8 mx-auto flex-wrap"
      >
        <div class="circle mr-4">
          <h1 v-if="!isLoadingCountdown" class="day" v-scrollanimation>
            {{ countdown.days }}
          </h1>
          <h3 v-if="!isLoadingCountdown" class="day-label" v-scrollanimation>
            días
          </h3>
        </div>
        <div class="circle mr-4">
          <h1 v-if="!isLoadingCountdown" class="day" v-scrollanimation>
            {{ countdown.hours }}
          </h1>
          <h3 v-if="!isLoadingCountdown" class="day-label" v-scrollanimation>
            horas
          </h3>
        </div>
        <div class="circle mr-4">
          <h1 v-if="!isLoadingCountdown" class="day" v-scrollanimation>
            {{ countdown.minutes }}
          </h1>
          <h3 v-if="!isLoadingCountdown" class="day-label" v-scrollanimation>
            minutos
          </h3>
        </div>
        <div class="circle">
          <h1 v-if="!isLoadingCountdown" class="day" v-scrollanimation>
            {{ countdown.seconds }}
          </h1>
          <h3 v-if="!isLoadingCountdown" class="day-label" v-scrollanimation>
            segundos
          </h3>
        </div>
      </div>

      <div class="flex items-center mx-auto mt-8">
        <div class="date-line" :style="{ height: '1px' }"></div>
        <h5 class="date" v-scrollanimation>{{ formattedDate }}</h5>
        <div class="date-line" :style="{ height: '1px' }"></div>
      </div>

      <h1 v-if="weddingCompleted" class="we-are-married mt-6" v-scrollanimation>
        ¡Ya nos casamos!
      </h1>
    </div>
  </header>
</template>

<script>
import placeholderImage from "@/assets/bg_placeholder.jpg";
export default {
  name: "Header",

  data() {
    return {
      intervalId: null,
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      weddingCompleted: false,
      placeholderImage,
      isLoadingCountdown: true,
      isCoverImageLoaded: false,
    };
  },

  props: {
    name: String,
    date: String,
    coverImages: Array,
    hasCountdown: Boolean,
  },

  computed: {
    formattedDate() {
      const dateObj = new Date(this.date);

      let day = dateObj.getDate();
      day = day < 10 ? "0" + day : day;

      let month = dateObj.getMonth() + 1; // January is 0!
      month = month < 10 ? "0" + month : month;

      let year = dateObj.getFullYear();

      return `${day}.${month}.${year}`;
    },
  },

  created() {
    this.loadImage();
    if (this.hasCountdown) {
      this.startCountdown();
    }
  },

  beforeUnmounted() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    loadImage() {
      const img = new Image();
      img.src = this.getCoverImage();
      img.onload = () => {
        this.isCoverImageLoaded = true;
      };
      img.onerror = () => {
        console.error("Failed to load image");
      };
    },

    calculateCountdown() {
      const weddingDate = new Date(this.date);

      const now = new Date();

      const distance = weddingDate - now;

      if (distance < 0) {
        this.weddingCompleted = true;
        clearInterval(this.intervalId);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown = { days, hours, minutes, seconds };

      this.isLoadingCountdown = false;
    },

    startCountdown() {
      this.intervalId = setInterval(this.calculateCountdown, 1000);
    },

    getCoverImage() {
      if (this.coverImages.length < 1) {
        return "";
      }

      return this.coverImages[0].src;
    },

    onImageLoad() {
      this.$refs.coverImage.classList.add("zoom-in");
    },
  },
};
</script>

<style scoped>
.special {
  @apply font-special text-4xl sm:text-5xl md:text-7xl mx-auto;
}
.circle {
  @apply w-12 h-12 xs:w-20 xs:h-20 sm:w-24 sm:h-24 md:w-40 md:h-40 rounded-full xs:border border-white flex flex-col justify-center;
}
.day {
  @apply mx-auto text-2xl sm:text-3xl md:text-5xl font-title;
}
.day-label {
  @apply mx-auto text-xs sm:text-sm md:text-base font-title;
}
.date {
  @apply font-title mx-2 sm:mx-3 sm:text-xl md:text-3xl;
}
.date-line {
  @apply bg-white w-12 sm:w-24 rounded;
}
.we-are-married {
  @apply font-title text-3xl sm:text-5xl md:text-7xl mx-auto;
}
.zoom-in {
  animation: zoomIn 10s ease-in-out forwards;
}
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>