<template>
  <section class="section">
    <transition name="modal">
      <gallery-modal
        v-if="showGallery"
        :images="images"
        :initialIndex="selectedIndex"
        @close="closeModal"
      ></gallery-modal>
    </transition>

    <w-c-title class="mb-8 sm:mb-16" title="Galería" v-scrollanimation="'enter-from-bottom'" />
    <div class="w-full mx-auto flex flex-col">
      <div ref="grid">
        <div
          v-for="(image, index) in images"
          :key="image.id"
          class="grid-item cursor-pointer hover:opacity-80 smooth"
          @click="openModal(index)"
        >
          <img
            v-lazy="{ src: image.src }"
            :alt="image.alt"
            class="w-full mt-3"
            @load="layoutMasonry"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import placeholderImage from "@/assets/bg_placeholder.jpg";
import GalleryModal from "../../../components/GalleryModal";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
export default {
  name: "Gallery",

  components: {
    GalleryModal,
  },

  data() {
    return {
      masonry: null,
      placeholderImage,
      showGallery: false,
      selectedIndex: null,
    };
  },

  props: {
    images: Array,
  },

  mounted() {
    imagesLoaded(this.$refs.grid, () => {
      this.masonry = new Masonry(this.$refs.grid, {
        itemSelector: ".grid-item",
        gutter: 10,
        percentPosition: true,
        transitionDuration: 0,
      });
    });
  },

  methods: {
    layoutMasonry() {
      if (this.masonry) {
        this.masonry.layout();
      }
    },

    openModal(index) {
      document.body.style.overflow = "hidden";
      this.showGallery = true;
      this.selectedIndex = index;
    },

    closeModal() {
      document.body.style.overflow = "";
      this.showGallery = false;
    },
  },
};
</script>

<style scoped>
.grid-item {
  width: 100%;
}
@media (min-width: 640px) {
  /* adjust the breakpoint as needed */
  .grid-item {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  /* adjust the breakpoint as needed */
  .grid-item {
    width: 49%;
  }
}
@media (min-width: 1280px) {
  /* adjust the breakpoint as needed */
  .grid-item {
    width: 32%;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>