import { createRouter, createWebHistory } from 'vue-router'
import Home from "../views/Home";
import NotFound from "../views/NotFound";

const routes = [
  {
    path: '/',
    component: NotFound,
    name: 'not_found'
  },
  {
    path: '/:slug',
    name: 'home',
    component: Home
  },
  {
    path: '/:slug/:code',
    name: 'homeWithCode',
    component: Home
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
