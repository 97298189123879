<template>
  <div class="flex items-center w-full justify-evenly">
      <div class="bg-primary-200 flex-grow rounded" :style="{ height: '1px' }"></div>
      <h1 class="display mx-3 xs:mx-4 sm:mx-6 md:mx-8">{{ title }}</h1>
      <div class="bg-primary-200 flex-grow rounded" :style="{ height: '1px' }"></div>
    </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>