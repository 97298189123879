import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import VueLazyLoad from 'vue3-lazyload'
import { createHead } from '@vueuse/head'

// Components
import WCTitle from "./components/WCTitle";

// Directives
import ScrollAnimation from "./directives/scrollanimation";

const app = createApp(App);
const head = createHead();

app.use(store).use(router).use(VueLazyLoad).use(head);
app.component('w-c-title', WCTitle);
app.directive('scrollanimation', ScrollAnimation);

app.mount('#app');
