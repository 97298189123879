<template>
  <div class="absolute inset-0 text-center">
    <custom-header
      :name="wedding.name"
      :date="wedding.date"
      :coverImages="wedding.coverImages"
      :hasCountdown="wedding.hasCountdown"
    />

    <invitation :invitation="wedding.invitation" />

    <events
      v-if="wedding.events.length > 0"
      :events="wedding.events"
      :guest="guest"
      :weddingName="wedding.name"
      :hasEventImages="wedding.hasEventImages"
    />

    <dress-code
      class=""
      :dressCode="wedding.dressCode"
      :showDressCodeIcons="wedding.showDressCodeIcons"
    />

    <gift-registry
      v-if="wedding.giftRegistries.length > 0 || wedding.acceptsEnvelope"
      :giftRegistries="wedding.giftRegistries"
      :acceptsEnvelope="wedding.acceptsEnvelope"
    />

    <confirmation
      v-if="wedding.hasDigitalConfirmation"
      :confirmationEnabled="wedding.confirmationEnabled"
      :foodRestrictionsEnabled="wedding.foodRestrictionsEnabled"
      :messageEnabled="wedding.messageEnabled"
      @setGuest="guest = $event"
    />

    <accommodations
      v-if="wedding.accommodations.length > 0"
      :accommodations="wedding.accommodations"
    />

    <gallery
      v-if="wedding.galleryImages.length > 0 && wedding.hasGallery"
      :images="wedding.galleryImages"
    />

    <div
      class="flex mt-16 text-content-400 mx-auto w-full justify-center text-sm"
      v-scrollanimation
    >
      <p class="mr-1">Hecha por</p>
      <a class="underline button-interactive font-bold" :href="getAdminUrl()" target="_blank">
        Daisi
      </a>
    </div>

    <div class="h-16"></div>
  </div>
</template>

<script>
import CustomHeader from "./sections/Header";
import Invitation from "./sections/Invitation";
import Events from "./sections/Events";
import DressCode from "./sections/DressCode";
import Confirmation from "./sections/Confirmation";
import GiftRegistry from "./sections/GiftRegistry";
import Accommodations from "./sections/Accommodations";
import Gallery from "./sections/Gallery";
export default {
  name: "Etiqueta",

  components: {
    CustomHeader,
    Invitation,
    Events,
    DressCode,
    Gallery,
    Confirmation,
    GiftRegistry,
    Accommodations,
  },

  data() {
    return {
      audioPlaying: false,
      guest: null,
    };
  },

  props: {
    wedding: Object,
  },

  methods: {
    getAdminUrl() {
      return process.env.VUE_APP_ADMIN_URL;
    },
  },
};
</script>