import { createStore } from 'vuex'

export default createStore({
  state: {
    snack: null,
  },
  getters: {
  },
  mutations: {
    setSnack(state, payload) {
      state.snack = payload;
      setTimeout(() => (state.snack = null), 3000);
    },
  },
  actions: {
    errorSnack({ commit }, payload) {
      commit("setSnack", {
        message: payload,
        type: "error",
      });
    },
    successSnack({ commit }, payload) {
      commit("setSnack", {
        message: payload,
        type: "success",
      });
    },
  },
  modules: {
  }
})
