<template>
  <div
    class="flex flex-col h-screen justify-center fixed z-50 inset-0 bg-opacity-90 bg-black"
  >
    <div class="absolute inset-0" @click="close"></div>
    <div class="flex items-center w-full h-full justify-evenly relative">
      <button
        class="button-interactive text-white button-interactive absolute right-4 top-4"
        @click.prevent="close"
      >
        <close-icon class="w-4 fill-current" />
      </button>

      <button
        v-if="images.length > 1"
        class="button-interactive text-white rotate-90 button-interactive"
        @click.prevent="previous"
      >
        <arrow-icon class="w-4 sm:w-6 md:w-8 fill-current" />
      </button>

      <transition name="fade" mode="out-in">
        <div :key="index" class="w-4/5 h-4/5 z-10" @click="close">
          <img
            v-lazy="images[index].src"
            class="w-full h-full object-contain"
          />
        </div>
      </transition>

      <button
        v-if="images.length > 1"
        class="button-interactive text-white -rotate-90 button-interactive"
        @click.prevent="next"
      >
        <arrow-icon class="w-4 sm:w-6 md:w-8 fill-current" />
      </button>
    </div>
  </div>
</template>

<script>
import ArrowIcon from "../icons/ArrowIcon";
import CloseIcon from "../icons/CloseIcon";
export default {
  name: "Gallery Modal",

  components: {
    ArrowIcon,
    CloseIcon,
  },

  data() {
    return {
      index: null,
    };
  },

  props: {
    images: Array,
    initialIndex: Number,
  },

  created() {
    this.index = this.initialIndex;
    document.addEventListener("keydown", this.handleKeydown);
  },

  destroyed() {
    document.removeEventListener("keydown", this.handleKeydown);
  },

  methods: {
    close() {
      this.$emit("close");
    },

    previous() {
      if (this.index === 0) {
        this.index = this.images.length - 1;
      } else {
        this.index--;
      }
    },

    next() {
      if (this.index === this.images.length - 1) {
        this.index = 0;
      } else {
        this.index++;
      }
    },

    handleKeydown(e) {
      if (e.key === "ArrowLeft") {
        this.previous();
      } else if (e.key === "ArrowRight") {
        this.next();
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>